import React from 'react';

const ArrowUp = ({ size = '15px', color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
        fillRule='evenodd'
        clip-rule="evenodd" 
        d="M9.70698 5.70703C9.51945 5.8945 9.26514 5.99982 8.99998 5.99982C8.73482 5.99982 8.48051 5.8945 8.29298 5.70703L4.99998 2.41403L1.70698 5.70703C1.51838 5.88919 1.26578 5.98998 1.00358 5.9877C0.741382 5.98543 0.49057 5.88026 0.305162 5.69485C0.119753 5.50944 0.0145843 5.25863 0.0123059 4.99643C0.0100274 4.73423 0.110822 4.48163 0.29298 4.29303L4.29298 0.293031C4.48051 0.10556 4.73482 0.000244141 4.99998 0.000244141C5.26514 0.000244141 5.51945 0.10556 5.70698 0.293031L9.70698 4.29303C9.89445 4.48056 9.99977 4.73487 9.99977 5.00003C9.99977 5.26519 9.89445 5.5195 9.70698 5.70703Z"
        fill={color}
    />
  </svg>
);

export default ArrowUp;
