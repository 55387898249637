import React from 'react';

const NextButton = ({ size = '20px', color = 'white' }) => (
    <svg 
        width={size} 
        height={size} 
        viewBox="0 0 20 20" 
        fill="none" 
        xmlns="http://www.w3.org/2000/svg">
        <path 
            fill-rule="evenodd" 
            clip-rule="evenodd" 
            d="M12.707 5.29303C12.8945 5.48056 12.9998 5.73487 12.9998 6.00003C12.9998 6.26519 12.8945 6.5195 12.707 6.70703L9.414 10L12.707 13.293C12.8892 13.4816 12.99 13.7342 12.9877 13.9964C12.9854 14.2586 12.8802 14.5094 12.6948 14.6948C12.5094 14.8803 12.2586 14.9854 11.9964 14.9877C11.7342 14.99 11.4816 14.8892 11.293 14.707L7.293 10.707C7.10553 10.5195 7.00021 10.2652 7.00021 10C7.00021 9.73487 7.10553 9.48056 7.293 9.29303L11.293 5.29303C11.4805 5.10556 11.7348 5.00024 12 5.00024C12.2652 5.00024 12.5195 5.10556 12.707 5.29303Z" 
            fill={color}
        />
    </svg>
);

export default NextButton;