import React from 'react';

const ArrowDown = ({ size = '15px', color = 'white' }) => (
  <svg
    width={size}
    height={size}
    viewBox='0 0 10 10'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
  >
    <path
        fillRule='evenodd'
        clip-rule="evenodd" 
        d="M0.293 1.29296C0.480528 1.10549 0.734836 1.00017 1 1.00017C1.26516 1.00017 1.51947 1.10549 1.707 1.29296L5 4.58596L8.293 1.29296C8.38525 1.19745 8.49559 1.12127 8.6176 1.06886C8.7396 1.01645 8.87082 0.988862 9.0036 0.987709C9.13638 0.986555 9.26806 1.01186 9.39095 1.06214C9.51385 1.11242 9.6255 1.18667 9.71939 1.28056C9.81329 1.37446 9.88754 1.48611 9.93782 1.60901C9.9881 1.7319 10.0134 1.86358 10.0123 1.99636C10.0111 2.12914 9.98351 2.26036 9.9311 2.38236C9.87869 2.50437 9.80251 2.61471 9.707 2.70696L5.707 6.70696C5.51947 6.89443 5.26516 6.99975 5 6.99975C4.73484 6.99975 4.48053 6.89443 4.293 6.70696L0.293 2.70696C0.105529 2.51943 0.000213623 2.26512 0.000213623 1.99996C0.000213623 1.73479 0.105529 1.48049 0.293 1.29296Z" 
        fill={color}
    />
  </svg>
);

export default ArrowDown;







