import React from 'react';
 const classes = {
    cls_1: {
        fill: "#6c63ff",
        stroke: "#000",
        strokeMiterlimit: "10",
        strokeWidth:"10px",
    },
    cls_2: {
        fill: "none",
    },
    cls_3: {
        fill:"#ff6584",
        stroke:"#000",
        strokeMiterlimit:"10",
        strokeWidth:"10px"
    }
 };


const Logo = (props) => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 406.55 401.18" className={props.className}>
    <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
            <ellipse style={classes.cls_1} cx="193" cy="200.13" rx="190.5" ry="191"/>
            <path style={classes.cls_2} d="M192.55,8.68c-105.21,0-190.5,85.51-190.5,191s85.29,191,190.5,191,190.5-85.51,190.5-191S297.76,8.68,192.55,8.68Zm82.5,275c-7,11-23-3-23-3s-56-49-139-15c0,0-11,7-17-7s9-18,9-18,94-44,171,25C276.05,265.68,282.05,272.68,275.05,283.68Zm20-51c-8.33,9.72-21,2-21,2s-69-56-173-20c0,0-15,6-19-10s9-19,9-19,95-43,198,21C289.05,206.68,307.05,218.68,295.05,232.68Zm21-41s-110-78-250-28c0,0-13.6,3.86-20-10-6-13,5-22,8-23s134-58,280,30c0,0,13,11,4,24S316.05,191.68,316.05,191.68Z"/>
            <path d="M275.05,283.68c-7,11-23-3-23-3s-56-49-139-15c0,0-11,7-17-7s9-18,9-18,94-44,171,25C276.05,265.68,282.05,272.68,275.05,283.68Z"/>
            <path d="M295.05,232.68c-8.33,9.72-21,2-21,2s-69-56-173-20c0,0-15,6-19-10s9-19,9-19,95-43,198,21C289.05,206.68,307.05,218.68,295.05,232.68Z"/>
            <path d="M338.05,184.68c-9,13-22,7-22,7s-110-78-250-28c0,0-13.6,3.86-20-10-6-13,5-22,8-23s134-58,280,30C334.05,160.68,347.05,171.68,338.05,184.68Z"/>
            <path style={classes.cls_2} d="M276.05,265.68s6,7-1,18-23-3-23-3-56-49-139-15c0,0-11,7-17-7s9-18,9-18S199.05,196.68,276.05,265.68Z"/>
            <path style={classes.cls_2} d="M295.05,232.68c-8.33,9.72-21,2-21,2s-69-56-173-20c0,0-15,6-19-10s9-19,9-19,95-43,198,21C289.05,206.68,307.05,218.68,295.05,232.68Z"/>
            <path style={classes.cls_2} d="M338.05,184.68c-9,13-22,7-22,7s-110-78-250-28c0,0-13.6,3.86-20-10-6-13,5-22,8-23s134-58,280,30C334.05,160.68,347.05,171.68,338.05,184.68Z"/>
            <path style={classes.cls_2} d="M338.05,184.68c-9,13-22,7-22,7s-110-78-250-28c0,0-13.6,3.86-20-10-6-13,5-22,8-23s134-58,280,30C334.05,160.68,347.05,171.68,338.05,184.68Z"/>
            <path style={classes.cls_2} d="M295.05,232.68c-8.33,9.72-21,2-21,2s-69-56-173-20c0,0-15,6-19-10s9-19,9-19,95-43,198,21C289.05,206.68,307.05,218.68,295.05,232.68Z"/>
            <path style={classes.cls_2} d="M275.05,283.68c-7,11-23-3-23-3s-56-49-139-15c0,0-11,7-17-7s9-18,9-18,94-44,171,25C276.05,265.68,282.05,272.68,275.05,283.68Z"/>
            <circle style={classes.cls_3} cx="339.05" cy="345.68" r="53"/>
            <path style={classes.cls_3} d="M314.05,263.68s41,10,49,7,35-84,41-235c0,0,1-36-32-33s-62.36,5.55-89,36c-14,16,3,84,3,84S315.05,246.68,314.05,263.68Z"/>
        </g>
    </g>
</svg>
);

export default Logo;